
.bg-light {
    background-color: #fff !important;
}

.card-img-top {
    max-height: 150px;
}

.dashbord-section .logout-btn {
    color: #7030a0;
    font-weight: 700;
    border: 1px solid #eee;
}

.more_options .MuiFormControlLabel-root {
    margin: 0 !important;
}

.more_options .MuiSwitch-root {
    width: 72px;
    height: 50px;
}

.more_options .MuiSwitch-switchBase {
    top: 6px;
    left: 7px;
}

.more_options .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(20px);
}

.more_options .MuiSwitch-track {
    border-radius: 16px;
}

.more_options .MuiSwitch-colorPrimary.Mui-checked {
    color: white;
}

.more_options .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #7030a0;
    opacity: 1;
}

.more_options .MuiFormControlLabel-label {
    font-weight: 600;
}
.more_options .MuiPaper-rounded{
    box-shadow: none;

}
.more_options .MuiPaper-rounded .MuiTab-wrapper{
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    color: black;
    width: auto;
}
.more_options .MuiPaper-rounded .Mui-selected .MuiTab-wrapper{
    border-bottom: 4px solid #8C58B1;
}
.card-img {
    height: 152px;
}

.main_header_right .LogoutBtn {
    float: none !important;
}

.dashboard-card .card {
    height: 200px;
    border: 5px solid #7030a0;
    border-radius: 12px;
    position: relative;
}

.dashboard-card {
    position: relative;
    height: calc(100vh - 159px);
}

.report_model {
    width: 90%;
}

.viewCheckInBtn{
    background: #0286ae;
    border: none;
    border-radius: 0;
    padding: 8px 20px;
    font-size: 20px;
    box-shadow: 5px 4px 2px 0 #c0c4c5;
}
.viewCheckInBtn:hover {
    background: #0286ae;
    border: none;
    border-radius: 0;
    padding: 8px 20px;
    font-size: 20px;
    box-shadow: 5px 4px 2px 0px #c0c4c5;
}
.viewCheckInBtn:active {
    background-color: #0286ae !important;
    border: none !important;
    border-radius: 0;
    padding: 8px 20px;
    font-size: 20px;
    box-shadow: 5px 4px 2px 0px #c0c4c5;
}

@media (max-width: 992px) {
    .dashboard-card {
        height: calc(100vh - 177px);
    }
    .released_content{
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .released_content .datacenter:first-child{
        margin-bottom: 20px;
    }
}

@media (max-width: 992px) {
    .dashboard-button {
        margin: 20px auto;
        padding: 0;
    }
}

.card-img img {
    height: 95%;
    margin-top: 7px;
    width: 88%;
}

.datacenter {
    padding: 30px;
    /*border: 1px solid #ebf1f8;*/
}

.cursor-pointer{
    cursor: pointer;
}

.datacenter .card-body {
    padding: 5px;
}

.start_btn {
    position: absolute;
    left: 30px;
    top: 23%;
}

@media (max-width: 1200px) {
    .getstard, .start_btn {
        position: relative !important;
    }
}

@media (max-width: 576px) {
    .getstard {
        width: 100% !important;
    }

    .dashboard-button {
        padding: 0 15px;
    }
    .dashboard-card{
        height: auto;
        margin-bottom: 20px;
    }
}

.modelHeader {
    background: #7030a0;
    color: #fff;
    height: 50px;
    padding: 12px;
}

.modelHeader span {
    color: #fff;
}

.checkBotton {
    padding-top: 35px;
}

.dashboard-card .checkBotton {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.checkinbtn {
    display: flex;
    justify-content: center;
}

.HandleCheckin {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    background-color: #7030a0;
    border-color: #7030a0;
}

.datacenter-container {
    height: auto;
}


.card2 {
    background-color: #7030a0;
}

.checkinbtn .btn-primary:hover {
    background-color: #7030a0;
    border-color: #ead5fe;
    /*color: white;*/
}


.checkinbtn .btn-primary:focus {
    color: white !important;
    background-color: #7030a0 !important;
}

/*  ================= Dashbord Btn ===*/
.contacts_btn {
    width: 267px;
    height: 154px;
    font-size: 25px;
    font-weight: 500;
    border: 15px solid white;
    background: transparent;
    color: black;
    border-radius: 0;
    position: relative;
}

.contacts_btn:after {
    position: absolute;
    content: "";
    top: -27px;
    right: -50px;
    width: 50px;
    height: 20px;
    background: white;
    transform: rotate(145deg);
}

.contacts_btn:hover {
    background-color: white !important;
    border: 1px solid #D2E0EF !important;
    /* color: white !important; */
}


/*profile-image-css*/
.imges {
    height: 80px;
    width: 100%;
    margin-bottom: 40px;
}

.imges img {
    width: 80px;
    height: 80px;
    padding: 3px;
    border-radius: 50%;
    border: 3px solid #7030a0;
    margin-top: 62px;
    margin-left: 10px;
}

/*
.imges img:after {
    content: "";
    position: absolute;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    left: calc(50% - 91.5px);
    top: 20px;
    border: 3px solid #7030a0;
}

.imges img:before {
    content: "";
    position: absolute;
    width: 190px;
    height: 190px;
    border-radius: 50%;
    left: calc(50% - 95px);
    top: 15.5px;
    border: 2px solid #7030a0;
}
*/

.myprofile-left-section {
    border: 0 !important;
    padding: 5px !important;
}

.h5, h5 {
    margin-bottom: 0rem;
}
.email_address{
    overflow: hidden;
    text-overflow: ellipsis;
}

.names {
    margin-left: 10px;
    margin-top: 65px;
    width: 60%;
    overflow: hidden;

}

.myprofile-left-section h3 {
    margin-bottom: 0px !important;
}

.modal-title-content {
    border-bottom: none !important;
}

/*my contact*/

/*.mycontactBotton {*/
/*    padding-top: 15px;*/
/*}*/

.mycontactbtn {
    display: flex;
}
.mycontactbtn > span{
    color: red;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
}
.mycontactbtn .wishes_description {
    padding: 10px;
    border: 1px solid #d2e0ef;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    color: #7f7f7f;
    text-align: left;
    height: 130px;
}

.mycontactbtn a {
    width: 100%;
}

.Handlemycontact {
    height: 65px;
    width: 100%;
    font-size: 19px;
    font-weight: bold;
    background-color: white;
    border: 1px solid #d2e0ef;
    border-radius: 0;
    color: black;
    height: 90px;
    display: flex;
    align-items: center;
}
.Handlemycontact .underline{
    border-bottom: 2px solid red;
}

.Handleletsdata {
    height: 65px;
    width: 280px;
    font-size: 19px;
    font-weight: bold;
    background-color: whitesmoke;
    border: 1px solid #d2e0ef;
    border-radius: 0;
}

.menu {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    color: #7030a0;
}

.Menu-Text {
    color: #7030a0;
    font-size: 12px;
    font-weight: bold;
}

.model-genie p {
    margin: 5px;
}

.modelbody {
    padding: 1rem !important;
}

.btngenie {
    background-color: #7030a0;
    border: none;
}

.mainDashboard .modelHeaderDashoboard {
    background: #7030a0;
    color: #fff;
    height: 45px;
    align-items: center;
}

.mainDashboard .GenieModelMainDashboard {
    left: 116px;
    /*top: 50%;*/
    width: 28%;
}

@media (max-width: 1024px) {
    .mainDashboard .GenieModelMainDashboard {
        left: 4px;
        top: 25%;
        width: 97%;
    }
}

@media (max-width: 768px) {
    .mainDashboard .GenieModelMainDashboard {
        left: 4px;
        top: 25%;
        width: 97%;
    }
}

.GenieModelMainDashboard {
    max-width: 420px;
    box-shadow: 6px 6px 1px 1px rgba(0, 0, 120, .2);
}

.shadowoftheBox {
    box-shadow: 6px 6px 1px 1px rgba(0, 0, 120, .2);
}

.shadowoftheBox .modal-lg {
    max-width: 1100px !important;
}

.GenieModelMainDashboard .modelTitle {
    font-size: 17px;
}

.mainDashboard #mainAccordianSummery-mainDash {
    border-bottom: 1px solid;
    background: #7030a0;
    color: #fff;
    min-height: 40px !important;
}

#mainAccordianSummery-mainDash span {
    color: #fff;
}

.mainDashboard .moreIcon:hover {
    color: red;
    cursor: pointer;
    height: 12px;
}

.mainAccordian-mainDash .MuiAccordionDetails-root {
    max-height: 160px;
    overflow: auto;
}

.dashboard-modal-title {
    font-size: 19px;
    font-weight: 600;
}

.HowToUse-Modal-Container .modal-content .modal-body p {
    margin-bottom: 0;
}

.HowToUse-Modal-Container .modal-content {
    box-shadow: 6px 6px 1px 1px darkgrey;
}

.modal_pass {
    position: absolute;
    right: 1%;
    top: 41%;
}

@media (max-width: 992px) {
    .modal_pass {
        display: none;
    }

    .datacenter-container {
        height: auto;
    }
}

.pass_dialog {
    width: 366px;
}

.pass_dialog .modal-body {
    /*padding: 6px;*/
}

.key-gold-img {
    margin-right: 15px;
    cursor: pointer;
}

.How-to-use-title {
    font-weight: bold;
    cursor: pointer;
}

.How-to-use-click-here-btn {
    float: right;
    font-weight: bold;
    font-size: 14px;
    color: red;
    cursor: pointer;
}

.manubutton {
    cursor: pointer;
}

.shadowoftheBox .toolTipBox {
    border: 2px solid black;
    padding: 18px;
    min-height: 230px;
    font-size: 19px;
}

/*.shadowoftheBox span{*/
/*    color: white;*/
/*}*/

.shadowoftheBox .toolTipBox p {
    margin: 0px;
}

.shadowoftheBox .modal-content {
    box-shadow: 6px 6px 1px 1px darkgrey;
}

.shadowoftheBox .nextToPreviousIcon {
    cursor: pointer;
}

.shadowoftheBox .nextToPreviousIcon:hover {
    color: green;
}

.main_header_right .MuiFormControlLabel-root {
    margin-right: 0px !important;
}

.mode {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.mainmodelheder .close span {
    color: black;
}

.quoteContainer {
    position: relative;
}

.quoteWrapper {
    margin-top: 140px;
}

.mainDashboard .quoteOftheDay {
    margin: 3px auto 0;
    max-width: 294px;
    border: 5px solid #00aae4;
    border-radius: 10px;
}

.getstard {
    margin: 3px auto 0;
    width: 500px;
    min-height: 350px;
    border: 5px solid #00aae4;
    position: absolute;
    overflow: auto;
    background: white;
}

.no_stard {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: gray;
}

.quoteOftheDay p {
    margin-bottom: 3px !important;
}

.quoteOftheDay p:first-child {
    margin-top: 3px !important;
}

.mainDashboard .reportBtn {
    cursor: pointer;
}

.mainDashboard .reportBtn:hover {
    color: #7030a0;
    text-decoration: underline;
}

.modelcontact span {
    color: black;
}

.dataMain .MuiFormControlLabel-root {
    width: 150px;
    margin-bottom: 0;
}

.resentOtpBtn {
    color: blue;
    cursor: pointer;
}

.resentOtpBtn:hover {
    text-decoration: underline;
}

.settingFor {
    align-items: center;
    margin-left: 10px;
}

.settingFor span {
    cursor: pointer;
    font-weight: 500;
}

.report_model {
    width: 90%;
}

.start_btn {
    position: absolute;
    top: 46px;
    left: 30px;
}

@media (max-width: 992px) {
    .change_password_btn {
        margin: auto;
    }

    .change_password_btn .Handlemycontact {
        width: 100% !important;
    }
}

@media (max-width: 576px) {
    .getstard {
        width: 100% !important;
    }

    .checkinbtn img {
        height: 100px;
    }

    .change_password_btn {
        width: 100% !important;
        padding: 15px 15px !important;
    }

    .change_password_btn .mycontactBotton {
        padding-top: 0;
    }

    .mutplefamily-container {
        padding: 0 15px;
    }

    .change_password_btn .Handlemycontact {
        width: 100% !important;
    }

    .mutplefamily-container .multifamily {
        width: 100% !important;
        padding: 10px !important;
    }
}
@media (max-width: 375px) {
    .more_options .MuiTabs-scroller {
        overflow: auto;
    }
    .more_options .MuiTabs-centered{
        width: 350px;
        overflow: auto;
    }
}

.maindash-custom-font {
    font-size: 20px;
    font-weight: 500;
    width: 100%;
}
.custom-border {
    border: 2px solid red;
    color: red;
    padding: 8px;
    box-shadow: 5px 4px 2px 0 #c0c4c5;
}

.mainDashboard-Container .mutplefamily-container {
    justify-content: center;
}
.text_reb{
    color: red;
    font-weight: 700;
    font-size: 23px;
}
.multifamily .pb-2{
    width: 70%;
    display: block;
    margin: auto;
}

.released_content .multifamily{
    box-shadow: rgb(181 223 228) 0px 7px 29px 0px;
    max-width: 530px;
}
.multifamily .datacenter:first-child{
    margin-bottom: 30px;
}
.multifamily .datacenter .Handlemycontact{
    margin: 0;
    width: 100%;
}

@media (max-width: 450px) {
    .more_options .MuiFormControlLabel-label {
        text-align: left;
    }

    .dashboard-button {
        padding: 0;
    }

    .dataMain .MuiFormControlLabel-root {
        width: 120px;
    }

    .dataMain {
        margin: 0 !important;
    }
}

.modal-title {
    line-height: 1;
}

.lets_start {
    display: flex;
    background: #00aae4;
    color: white;
    height: 40px;
    font-size: 20px;
    font-weight: 700;
    justify-content: end;

}

.lest_model_body{
    padding: 15px;
}

.dashboard-button {
    padding: 5px;
    width: 900px;
    box-shadow: rgb(181 223 228) 0px 7px 29px 0px;
}
.dashboard-button .Handlemycontact {
    text-align: start;
}
.Handlemycontact:active,
.Handlemycontact:focus,
.Handlemycontact:hover{
    background: transparent !important;
    border-color: #d2e0ef !important;
    color: black !important;
    box-shadow: none !important;
}
.Image_home {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Image_home img{
    height: 100%;
}


@media (max-width: 576px) {
    .dashboard-button .datacenter {
        flex-wrap: wrap;
    }
    .dashboard-button .datacenter .Handlemycontact{
        justify-content: start;
    }
    .dashboard-button .datacenter .Handlemycontact .Image_home{
        height: auto;
    }
    .dashboard-button .datacenter .Handlemycontact img{
        height: 40px;
    }
    .mycontactbtn .Handlemycontact{
        display: flex;
    }
    .lets_start{
        justify-content: space-between;
    }
    .lets_start .lest_model_title p{
        padding: 0 !important;
    }
    .wishes_icon {
        height: 40px;
        border-radius: 50%;
    }
}
.wishes_icon {
    height: 55px;
    border-radius: 50%;
}
.Image_home_wishes {
    width: 55px;
    margin-right: 34px;
    display: flex;
    align-items: center;
}
.invalid-check-modal .modal-body {
    padding: 25px;
}
.invalid-check-modal .modal-header span{
   color: white;
}
.checkin-dates-modal .modal-body {
    padding: 25px;
}
.checkin-dates-modal .modal-header span {
    color: white;
}
.checkin-dates-data-container {
    max-height: 350px;
    overflow: auto;
}
.checkin-dates-data-container hr{
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}
.checkin-dates-data-container .date-label{
    margin-top: 2px;
}


/*cookie concent responsive*/
@media (max-width: 768px) {
    .CookieConsent{
        width: 100% !important;
        flex-wrap: nowrap !important;
    }
    .multifamily:first-child {
        width: 100%;
    }
}
.CookieConsent .test{
    margin-top: 0 !important;
}
@media (max-width: 425px) {
    .CookieConsent{
        height: auto !important;
        flex-direction: row-reverse;
    }
    .CookieConsent .test{
        margin-top: 0 !important;
        flex: auto !important;
    }
}

.guest_list_model .modal-header .modal-title{
    font-size: 22px;
}
.guest_list_model .guest-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.guest_list_model .modal-body .guest_name{
    font-size: 18px;
    font-weight: 500;
}
.guest_list_model .modal-body .line{
    margin: 5px 0;
    border-color: #f3f3f3;
}

.react-tel-input .form-control {
    width: 100% !important;
    padding: 6px 6px 6px 48px !important;
}