.selected-row{
    background-color: #d4d4d4;
}

.wrapped-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Elipsis after one line*/
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}