.contact-form-section .Form-invite-contain {
    position: relative;
    padding: 0;
    width: 100%;
}
.navigation-invite {
    position: relative;
}
.contact-form-wrapper-invite {
    padding: 44px 60px 46px 60px !important;
}
.invite-Number .PhoneInputInput {
    border: 1px solid darkgray;
    border-radius: 0px;
    padding: 14px 0;
}
.invite-Number .PhoneInputCountry {
    border: 1px solid darkgrey;
    padding: 4px;
    border-radius: 0px;
}
.Form-invite-contain .email_input{
    padding: 0 60px 44px;
}
.Form-invite-contain .email_input .MuiFormControl-fullWidth{
    width: 100% !important;
}
.google_login {
    padding: 3.5px 150px !important;
}
.email_input .MuiInput-underline:before {
   border: none !important;
}

.email_input .MuiInput-underline:after {
    border: none;
}
.email_input .MuiInputLabel-formControl {
    color: #cacaca !important;
}
.email_input .password-invite-form .info-icon {
    position: absolute;
    top: 36% !important;
    right: 9px !important;
    cursor: pointer !important;
    color: #868383 !important;
}
.login_btn .googleLoginBtn {
    width: 100%;
}

.googleLoginBtn div{
    display: none!important;
}
.googleLoginBtn img{
    height: 28px;
}

.login_btn .googleLoginBtn span {
    width: 100%;
    text-align: center;
}

@media (max-width: 768px) {
    .genie_comment {
        position: relative !important;
    }
    .contact-form-wrapper-invite {
        padding: 30px 30px !important;
    }
    .term_view {
        position: relative !important;
    }
    .genie_comment {
        justify-content: center;
        display: flex;
    }
    .genie_img_invite {
        width: 150px !important;
    }
}
.term_checkbox .MuiFormControlLabel-root {
    margin-right: 0 !important;
}
.term_checkbox {
    display: flex;
}
.term_view {
    top: 10px;
    position: relative;
}
.blue-border .modal-content {
    border: 1px solid #007bff;
}

.invite_signup_page .MuiFormControl-fullWidth{
    width: 100% !important;
}