.login_form_page{
    background: url("../../../assets/images/LandingPage/carousel_1.jpg") no-repeat;
    width: 100%;
    height: 100vh;
    background-size: cover;
    min-height: 920px;
    position: relative;
}
.login_form_page:after{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .1);
}
.login-header {
    justify-content: center;
}


/* ===================== ===================== */
.contact-form-section .contact-form-section-wrapper {
    position: relative;
    padding: 0;
    top: 50px;
    width: 100%;
}

.login_form {
    min-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navigation_Header {
    position: relative;
}

.contact-form-section .background-pattern {
    position: absolute;
    top: -40px;
    left: -40px;
    z-index: -1;
    width: 115%;
    height: 100%;
}

.contact-form-wrapper {
    filter: drop-shadow(0px 35px 25px rgba(0, 114, 255, .4));
}

.contact-form {
    background: transparent;
}

@media (max-width: 320px) {
    .contact-form-section .background-pattern {
        position: absolute;
        top: -40px;
        left: -12px;
        z-index: -1;
        width: 108%;
        height: 100%;
    }
}

.errorcolor {
    background: linear-gradient(
            45deg, rgba(128, 226, 255, 1) 20%, rgba(153, 157, 253, 1) 50%, rgba(255, 183, 246, 1) 80%) !important;
    border: none;
    color: white !important;
}

.form-floating label {
    padding: 1px 1px !important;
}

.back_btn {
    position: absolute;
    top: 63px;
    left: 76px;
    color: #5178e8;
}

.back_btn .back_icon {
    height: 40px !important;
    width: 38px !important;
    cursor: pointer;

}

.pass_show {
    position: absolute;
    right: 20px;
    top: 50%;
    color: gray;
    cursor: pointer;
    transform: translateY(-50%);
}

.forgotPassword-Link {
    cursor: pointer;
    padding-top: 5px;
}

.LoginAction-Container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.googleLogin {
    display: flex !important;
}
.googleLogin button{
    opacity: 1!important;
}
.googleLogin .googleLoginBtn {
    color: black !important;
    justify-content: center !important;
    text-align: center !important;
    margin-top: 10px;
    border-radius: 5px !important;
    width: 100%;
    border: 1px solid #afafaf !important;
}

.googleLoginBtn > div {
    padding-right: 0px !important;
}

.main-Btn {
    display: flex;
    justify-content: center !important;
    width: 100%;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    border: 1px solid #afafaf !important;
}

.main-Btn span {
    font-size: 14px;
    font-weight: bold;
}

.main-Btn .googleImage {
    width: 41px !important;
    padding: 10px;

}

.main-Btn span {
    padding-left: 0 !important;
}

.kep-login-facebook {
    margin-top: 10px;
    width: 100%;
    height: 43px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: white !important;
    color: black !important;
    font-size: 19px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    border-radius: 5px !important;
    border: 1px solid #afafaf !important;
}

.contact-form-section .reCaptcha {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px;
    flex-direction: column;
    align-items: center;
}

.contact-form-section .CaptchaError {
    color: red;
    text-transform: capitalize;
}


@media (max-width: 576px) {
    .main-heading{
        display: block;
    }
    .back_btn {
        position: absolute;
        top: 65px;
        left: 16px;
    }
    .login_form_page{
        background-position: 50%;
    }
}
@media (max-width: 325px) {
    .back_btn {
        top: 45px;
    }
}