.react-tag-input {
	background: #e1e1e1;
	color: #333;
	border: 1px solid #e1e1e1;
}
.react-tag-input__input {
	background: transparent;
	color: black;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
	color: #fff; /* Input placeholder */
}
.react-tag-input__tag {
	background: #7030a0 !important;
}
.react-tag-input__tag__remove {
	background: #d4d4d4;
}
.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
	background: #333; /* X icon in remove button */
}
.react-tag-input__tag__content {
	color: #fff;
}
.btn-sm-height {
	height: 30px;
	padding: 2px 6px;
}
.notes-form .form-group {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
}
.custom-dates label {
	min-width: 107px !important;
}
.notes-form .form-group label {
	min-width: 135px;
	margin: 0;
}
.notes-form .form-group.notes-note-container {
	align-items: flex-start;
}

.notes-form .form-group.notes-note-container label {
	margin-top: 3px;
}
.input-border {
	border: 1px solid #ced4da;
	padding: 5px;
}
.cancelButton {
	border: none;
}
.ShareRelease-Modal-Header {
	background-color: #7030a0;
	border-color: #7030a0;
	color: white;
}

.modal-style .modal-lg{
	max-width: 610px;
}

.ShareRelease-Modal-Headers .close span{
	color: white;
}
.ShareRelease-Modal-Headers {
	color: white;
	background: #007bff;
}
.lookups_modal .modal-content{
	border: 2px solid #007bff;
}

.ShareRelease-Container {
	border: solid 3px;
	padding: 8px;
	margin-bottom: 10px;
}
.ShareRelease-Modal-Header .close span {
	color: white;
}
.newCount-Container {
	border: solid 2px;
	padding: 2px;
	min-width: 50px;
	text-align: right;
	height: 25px;
	font-size: 12px;
	width: 120px;
}

.Section-Container {
	border: solid 1px;
	padding: 6px;
	margin: 5px 0px;
}
.MuiTabs-flexContainer a:hover {
	color: white;
	text-decoration: none;
}
.Mui-selected:hover {
	color: #7030a0 !important;
	text-decoration: none;
}
.MuiTab-textColorInherit.Mui-selected {
	background-color: white;
	color: #7030a0;
}
.MuiTabs-indicator {
	display: none;
}

.demo-wrapper {

}
.rdw-editor-toolbar {
	border-bottom: 3px solid #ced4da !important;
}
.box {
	border: 1px solid #ced4da;
	padding: 10px;
	margin-bottom: 10px;
}
.custom-label {
	width: 100px;
}
.section1-custom-checkbox .MuiCheckbox-root {
	padding: 1px;
}
.newReleaseMyInfo-container .MuiOutlinedInput-input,
.newReleaseMyInfo-container .MuiOutlinedInput-notchedOutline {
	padding: 2px;
	text-align: center;
	font-size: 12px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
.custom-tab-container .MuiBox-root {
	padding: 0px !important;
}
.MuiInputBase-input {
	height: 100% !important;
}

.custom-tab-container {
	/*min-height: 193px;*/
	border: 1px solid #ced4da;
	padding: 10px;
	margin-bottom: 10px;
}
.demo-editor {
	padding: 0 .375rem .75rem;
}

.form-control:disabled {
	background-color: #ffffff !important;
}
.marginLeft {
	margin-left: 70px;
}
.custom-file-input:lang(en)~.custom-file-label::after {
	background: grey;
	color:white;
}

@media (max-width: 768px) {
	.marginLeft{
		margin-left: 0;
	}
}
@media (max-width: 576px) {
	.release_items{
		width: 100%;
	}
	.marginLeft{
		display: block !important;
	}
	.marginLeft .newReleaseMyInfo-container{
		margin: 0 !important;
	}
	.newReleaseMyInfo-container form{
		display: block !important;
	}
	.newReleaseMyInfo-container form .makeStyles-root-2{
		display: flex;
		align-items: center;
	}
	.newReleaseMyInfo-container form .MuiTextField-root{
		margin: 10px 0 !important;
	}
}
