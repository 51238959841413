.otpContainer .card {
    padding: 25px;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #7777;
    height: 350px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.otpContainer .container {
    height: 100vh
}

.otpContainer .mobile-text {
    color: #989696b8;
    font-size: 15px
}

.otpContainer .container .form-control {
    margin-right: 12px;
    text-align: center;
}

.otpContainer .container .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff8880;
    outline: 0;
    box-shadow: none
}

.otpContainer .cursor {
    cursor: pointer
}

.otpInput input {
    width: 50px !important;
}

.Exit_btn {
    position: absolute;
    right: 35%;
    top: 120%;
}

.logo_keystomywish {
    height: 60px;
}

@media (max-width: 375px) {
    .logo_keystomywish {
        height: 40px;
    }
    .otpInput input {
        width: 36px !important;
    }
}

.otp_header {
    position: relative;
    padding-top: 32px;
    width: 100%
}
.exit_button {
    background-color: #7030a0;
    color: white;
    border: none;
}

.exit_button .exitAppICon{
    height: 17px !important;
}

.otpContainer .VerificationGenie{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-60%);
}