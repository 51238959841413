.main-wishes{
    display: flex;
}
.main-wishes img{
    height:45px;
    width:45px;
    margin-left:10px;
    border-radius:50%;
    margin-top:8px;
    border: 2px solid #6d56be;
}
.WishesbyUser{
    overflow: hidden;
}
.WishesbyUser Button{
    margin: 10px;
    float: right;
    overflow: hidden;
}
.main-container-wishes{
    margin: 0 auto;
    margin-bottom: 4px;
}

.wishesrelease {
    display: flex;
    height: 50vh;
    justify-content: center;
    align-items: center;
}

@media (max-width: 576px) {
    .mainreleasedata {
        width: 100% !important;
    }
    .wishesrelease {
        padding: 0 15px;
        height: auto;
    }
}

