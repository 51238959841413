/*.footer {*/
/*  display: none;*/
/*}*/

.custom-header {
  background-color: #7030a0;
  border-color: #7030a0;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
}
.custom-link-btn{border: 1px solid #eee;}
.custom-link {
  background-color: #7030a0;
  border-color: #7030a0;
  color: white;
  justify-content: center;
  align-items: center;
}
.header-title  {
  font-size: 22px;
}
.fixed-right {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.dashbord-section .header-section{padding: 10px 0}
.dashbord-section .header-section .right-side-text{top: 21px; align-items: center;}

@media (max-width: 768px) {
  .dashbord-section .header-section{padding: 30px 0;}
  .dashbord-section .header-section .right-side-text{top: 65px;}
}
.custom-link:hover{
  background: #7030a0;
}