.login_form{
    background: url("../../../assets/images/LandingPageNew/SignUpBg.png")  !important;
    background-size: cover !important;
}

.login_form .form_content{
    margin-top: -9%;
}
.apple-button{
    background-color: white;
    padding: 12px;
    border: 1px solid #afafaf;
    border-radius: 3px;
    font-family: none;
    Height: 56px;
    font-size: 14px;
    width: 100%;
    margin-top: 7px;
}
/*.apple-button img{*/
/*    margin-left: -20px;*/
/*}*/
@media (max-width: 768px) {
    .login_form {
        min-height: auto !important;
    }
}