.firstsection{
    margin-left: 50px;
    margin-top: 20px;
}
.textfild {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin-top: 20px;
    margin-left: 50px;
}
.textfild .MuiFormControl-root:nth-child(1){
    margin-bottom: 20px !important;
}
.Should{
    max-width: 900px;
    margin: auto;
}
.ViewWishes-section .MuiInputBase-input {
    height: auto !important;
}

@media only screen and (max-width: 400px) {
    .textfild {
        width: 250px;
    }
}
.withDescriptionTitle {
    font-size: 16px;
    max-width: 900px;
    margin: auto;
    font-weight: bold;
}
.withDescriptionTitle h5{
    font-size: 16px;
    font-weight: bold;
}
.withDescriptionTitle td{
    font-size: 15px !important;
    font-weight: 500;
}

.withDescriptionField {
    margin-top: 5px !important;
}
.notescontent {
    border: 1px solid darkgray;
    width: 900px;
    padding: 10px;
    border-radius: 2px;
    height: 320px;
    overflow-y: auto;
}

@media (max-width: 992px) {
    .withDescriptionField{
        margin: 0 0 15px !important;
    }
}