.time-text p {
    margin: 0;
}

.btnText Button {
    width: 120px;
    margin-bottom: 10px;
    border-radius: 18px;
    background-color: #7030a0;
}

.btnView Button {
    min-width: 180px;
    align-content: space-evenly;
}

.modelData strong {
    width: 120px;
}

.plan-title {
    font-size: 20px;
    font-weight: bold!important;
    color: black!important;
}
.leftPanel{
    width: 380px;
    text-align: center;
}
.select-plan .Standard-features-main{
    margin-top: 40px;
}
.plan-name {
    font-size: 19px;
}

.DescriptionMain Card {
    border: 1px solid;
}

.mainService #demoEditor {
    max-width: 900px !important;
}

#notes-content {
    width: 500px !important;
}

@media (max-width: 768px) {
    .mainContainerMyPlan {
        flex-wrap: wrap;
    }

    .mainContainerMyPlan .leftPanel, .mainContainerMyPlan .mainService {
        width: 100%;
    }

    .mainContainerMyPlan .leftPanel {
        margin: 0 0 15px !important;
    }

}

@media (max-width: 538px) {
    .mainContainerMyPlan {
        display: flex;
        flex-wrap: wrap;
    }

    .mainContainerMyPlan .leftPanel {
        width: 100%;
    }
    .timezone{
        flex-wrap: wrap;
    }
    .timezonedata{
        width: 100% !important;
    }
}

.payment_details {
    /*border: 2px solid gray;*/
    margin-bottom: 15px;
    padding: 10px;

}

.card-plan-radio fieldset.MuiFormControl-root {
    width: 100%;
}

.notes-editor{
    height: calc(100% - 50px);
    width: 100%;
}