.newPassword .contact-form .button {
    width: 100%;
    margin-top: 29px;
    padding: 0;
}

.contact-form-wrapper .alert {
    margin-bottom: 0px;
}

.form-floating1 {
    display: flex;
    justify-content: space-between;
}

.form-floating2 {
    display: flex;
}
.forget_form {
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notAllowed {
    cursor: not-allowed;
}

.form-floating-email {
    margin-top: 0px;
}

.form-floating-email .MuiFormLabel-root {
    font-weight: bold;
}

.form-floating-email:nth-child(n + 2) {
    margin-top: 10px;
}
.forgot-pwd-padding {
    padding: 54px 30px 76px 30px !important;
}
.forgot_header .back_btn {
    position: absolute;
    top: 53px;
    left: 30px;
    color: #5178e8;
}


@media (max-width: 1140px) {
    .forgot_password .cord_btn{
        width: 50%;
    }
    .forgot_password .cord_btn .MuiButton-contained{
        float: right;
    }
}

@media (max-width: 768px) {
    .forgot_password .cord_btn{
        width: 70%;
    }
    .forgot_password .cord_btn .MuiButton-contained{
        float: right;
    }
}


@media (max-width: 576px) {
    .forgot_password{
        flex-wrap: wrap;
    }
    .forgot_password .cord_btn{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .forgot_header .back_btn {
        top: 15px;
    }
    .forgot_password .form-floating2{
        width: 100% !important;
    }
}