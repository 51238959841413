.btnsave {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.timezone {
    padding-bottom: 6px;
}
.timezonedata {
    width:288px;
}
.mainCardTimeSlot .cards {
    padding: 0.9rem !important;
}

.hederpassword{
    background-color: #c898ec;
    color: black;
    font-weight: bold;
    text-align: center;
    font-size: 21px;
}

.mainCardTimeSlot{
    width: 500px;
    align-items: center;
}

.mainCardTimeSlot p{
    font-size: 20px;
    font-weight: bold;
}

.MainTimeZoneSelection .MuiFormControlLabel-root{
    margin-right: 1px;
}

.mainCardTimeSlot .cards{
    border:1px solid #c898ec;
}