.seconddata {
    border: 2px solid black;
    padding: 5px;
}
.thirddata {
    padding-top: 10px;
    padding-bottom: 10px;
}
.textfildsdata {
    padding-bottom: 2px;
}
.boldtata {
    font-weight: 600;
}
.datatexts {
    padding-top: 12px;
}

/* ===========  ============ */
.Welcome_modals{
    font-family: 'Varela Round', sans-serif;
}
.Welcome_modals .modal-content {
    padding: 0 0 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
}
.Welcome_modals .icon-box {
    color: #fff;
    width: 95px;
    height: 95px;
    display: inline-block;
    border-radius: 50%;
    z-index: 9;
    border: 5px solid #fff;
    padding: 15px;
    text-align: center;
}
.Welcome_modals .icon-box i {
    font-size: 64px;
    margin: -4px 0 0 -4px;
}
.Welcome_modals .modal-header {
    background: #47c9a2;
    border-bottom: none;
    position: relative;
    text-align: center;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    padding: 35px;
}
.Welcome_modals .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    text-shadow: none;
    opacity: 1;
    z-index: 2;
}
.Welcome_modals .modal_title h1{
    color: #434e65;
}

.Welcome_modals .btn i {
    margin-left: 1px;
    font-size: 20px;
    float: right;
}
.Welcome_modals .drop-shadow-1 {
    filter: none;
}
.Welcome_modals .background-pattern {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.Welcome_modal .background-pattern-radius .background-pattern-img,
.Welcome_modal .background-pattern-radius .background-pattern-gradient{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}