.login-signup-link {
    color: blue;
    cursor: pointer;
}
.appContainer{
    display: flex;
    justify-content: center;
}
.appContainer .img2{
    height: 40px;
    width: 130px;
    margin-bottom:5px;
}
.appContainer .img1{
    margin-right: 5px;
    height: 40px;
    margin-bottom: 5px;
}
#tooltip-bottom{
 width: 400px !important;
}

.tooltip-inner {
    max-width: 350px;
    color: #3e3e3e;
    text-align: center;
    background-color: #fff;
    border-top: 3px solid red;
    position: relative;
    left: 10px;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.pass_input{
    position: relative;
}
.pass_input .info-icon{
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 10px;
}
.pass_input .info-icon:hover{
    color: red;
}



@media screen and (max-width: 1600px) and (min-width: 1140px) {
    .appContainer .img1 {
        height: 40px;
    }
    .appContainer .img2 {
        height: 40px;
        width: 130px;
    }
}