.listing{
    padding: 0 80px;
}
.listing .MuiPaper-elevation1{
    box-shadow: none !important;
    border-radius: 0 !important;
}
.listing .table{
    border: 1px solid gray;
}
.listing .table td {
    padding: 12px;
}
.listing thead tr,
.listing thead th{
    border-bottom: 2px solid gray !important;
}
.listing thead th,
.listing tbody td{
    border: 1px solid gray !important;
}
.listing tbody td{
    word-break: break-all;
}
.listing thead th:nth-child(1),
.listing tbody td:nth-child(1)
{
    width: 25%;
    text-align: left;
}
.listing thead th:nth-child(2),
.listing tbody td:nth-child(2)
{
    width: 15%;
    text-align: left;
}
.listing thead th:nth-child(3){
    width: 5%;

}
.listing thead th:nth-child(4),
.listing tbody td:nth-child(4)
{
    width: 8%;
    text-align: left;

}
.listing thead th:nth-child(5){
    width: 5%;

}

@media (max-width: 1700px) {
    .listing .table {
        width: 1700px;
    }
}

@media (max-width: 576px) {
    .listing{
        padding: 0 15px;
    }
    .genie_img_listing img{
        width: 30%;
    }
}