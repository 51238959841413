.main_footer{
    background: ##f2f4f7;
    padding: 28px 28px 0;
}
.main_footer .logo_img{
    margin-bottom: 20px;
}
.social_media_icons{
    margin-bottom: 24px;
}
.social_media_icons ul{
    list-style: none;
    display: flex;
}
.social_media_icons ul .social_media_items{
    padding: 0 10px;
}
.social_media_icons ul .social_media_items a{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #008EFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.social_media_icons ul .social_media_items svg{
    color: white;
}
.footer_menu {
    padding: 20px;
    border-bottom: 1px solid #CACACA;
}
.footer_menu ul{
    list-style: none;
    display: flex;
}
.footer_menu ul li:not(:last-child){
    padding-right: 52px;
}
.footer_menu ul li a{
    font-size: 22px;
    color: #404040;
}
.copyright{
    padding: 15px;
}

@media (max-width: 768px) {
    .footer_menu ul li:not(:last-child){
        padding-right: 30px;
    }
    .footer_menu ul li a{
        font-size: 20px;
    }
}