.updated-dummybtn {
    background: transparent;
    border: 1px solid black;
    padding: 10px 8px;
}

.updatepolicy {
    position: fixed;
    left: 35%;
    top: 0;
    background: #fff;
    z-index: 2;
    width: 30%;
    min-height: 33vh !important;
    transition: 0.3s ease;
    transform: translate(0, -100%);
}
.updatepolicy.active {
    width: 60% !important;
    height: 82% !important;
    top: -30% !important;
    left: 50% !important;
    transform: translate(-50%, 50%) !important;
    padding: 45px 0 !important;
    overflow: auto;
}
.updated-btn {
    text-align: right;
    margin-right: 20px;
}
.updated-btn .cancel-btn {
    background: transparent;
    color: #7030a0;
}
.updated-btn button {
    background-color: #7030a0;
    padding: 10px 20px;
    border: 1px solid;
    color: white;
    border-radius: 5px;
}
.privacy{
    color: #0056b3;
    margin-bottom: 0;
    cursor: pointer;
}
