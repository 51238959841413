.landing-section {
  background: #4e5f7d;
  height: 100%;
  color: white;
  padding-bottom: 57px;
  text-align: center;
  padding-top: 56px;
}

.landing-section .landing-inner-section h1 {
  font-weight: 700;
  text-transform: capitalize;
}
.landing-section .landing-inner-section h3 {
  padding-top: 30px;
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 0;
}
.landing-section .landing-inner-section p {
  font-size: 15px;
  padding-top: 30px;
  margin-bottom: 0;
}
.landing-section .landing-inner-section h2 {
  font-weight: 700;
  text-transform: capitalize;
}

.common_border {
  position: relative !important;
  height: 182px !important;
  width: 182px !important;
  border-radius: 50%;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #3c4c66;
  border-right: 3px solid #3c4c66;
  border-top: 3px solid #3c4c66;
}

.landing-inner-section-image .border-section {
  top: 0%;
  left: 25%;
  transform: rotate(45deg);
}
.landing-inner-section-image span {
  border: 2px solid #3c4c66;
  width: 118px;
  height: 0;
  position: absolute;
  bottom: 3px;
  left: 76%;
  top: 190px;
  transform: rotate(45deg);
}
.landing-inner-section-image img {
  position: absolute;
  width: 173px;
  height: 173px;
  top: 0;
  left: 0;
  transform: rotate(-45deg);
}

.inner-div-bg {
  background: #113a58;
  width: 176px;
  height: 176px;
  border-radius: 50%;
  border: 6px solid #4e5f7d;
}

.landing-center-div {
  background: #3e4d64;
  padding: 37px 0;
  width: 500px;
  margin: 0 auto;
  z-index: 1;
  border-radius: 119px;
}
.landing-center-div h4 {
  padding-bottom: 20px;
}
.landing-center-div h4:last-child {
  padding-bottom: 0;
}

.landing-inner-section-image .border-section-02 {
  left: 15%;
  top: 54px;
  transform: rotate(6deg);
}
.landing-inner-section-image .border-section-02 .inner-div-bg {
  background: #d1dfe8;
}
.landing-inner-section-image .border-section-02 img {
  transform: rotate(-4deg);
}
.landing-inner-section-image {
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
}
.landing-inner-section-image .border-section-03 {
  top: 54px;
  left: 38%;
  transform: rotate(84deg);
}
.landing-inner-section-image .border-section-03 .inner-div-bg {
  background: #e55a3d;
}
.landing-inner-section-image .border-section-03 img {
  transform: rotate(-82deg);
}
.landing-inner-section-image .border-section-04 .inner-div-bg {
  background: #bce0da;
}
.landing-inner-section-image .border-section-8 .inner-div-bg {
  background: antiquewhite;
}
.landing-inner-section-image .border-section-06 .inner-div-bg {
  background: lavender;
}
.landing-inner-section-image .border-section-07 .inner-div-bg {
  background-color: lightgray;
}

.landing-inner-section-image .border-section-04 {
  left: 29%;
  top: 48px;

  transform: rotate(-45deg);
}
.landing-inner-section-image .border-section-04 img {
  transform: rotate(45deg);
}
.landing-inner-section-image .border-section-05 .inner-div-bg {
  background: #d1dfe8;
}
.landing-inner-section-image .border-section-05 {
  left: -38%;
  top: 59px;
  transform: rotate(134deg);
}

.landing-inner-section-image .border-section-05 img {
  transform: rotate(-134deg);
}

.border-section-06 {
  left: 16%;
  top: -46px;

  transform: rotate(-97deg);
}
.landing-inner-section-image .border-section-06 img {
  transform: rotate(99deg);
}
.landing-inner-section-image .border-section-07 {
  top: -26px;
  left: 35%;
  transform: rotate(196deg);
}
.landing-inner-section-image .border-section-07 img {
  transform: rotate(164deg);
}

.border-section-8 {
  top: 0%;
  left: 25%;
  transform: rotate(-135deg);
}

.landing-inner-section-image .border-section-8 img {
  transform: rotate(134deg);
}

.signupandlogingBtn {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}
.signupandlogingBtn button {
  background-color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 600;
}

/* modal css */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.ReactModal__Content {
  width: 50% !important;
  height: 82% !important;
  top: -30% !important;
  left: 50% !important;
  transform: translate(-50%, 50%) !important;
  padding: 45px 0!important;
}

/* tab */
.landing-section .react-tabs__tab {
  background: #4e5f7d !important;
  color: white !important;
}
.landing-section .react-tabs__tab-list {
  border-bottom: 1px solid transparent !important;
}
.ReactModalPortal .react-tabs .react-tabs__tab-list {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}
.ReactModalPortal .react-tabs__tab {
  width: 50% !important ;
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 14px 0;
  cursor: pointer;
  text-align: center;
  width: 16.666%;
  color: white;
}
.ReactModalPortal .react-tabs__tab-list {
  background: #4e5f7d;
  text-align: center;
  color: white;
  font-weight: 600;
}

.ReactModalPortal .react-tabs__tab--selected {
  background: #fff;
  border-color: transparent;
  color: black;
  border-radius: 0;
  bottom: 0;
}
.ReactModalPortal {
  position: relative;
  z-index: 5;
}
.appContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center
}

.landing-form-section .custom-btn {
  background: #4e5f7d;
}

/* share-content-section */
.landing-section .share-content-section {
  margin-top: 100px;
  text-align: center;
}
.landing-section .share-content-section p {
  font-size: 17px;
  color: yellow;
  margin-bottom: 10px;
  padding-top: 0;
  font-weight: 600;
}

/* service plans */
.serviceplans-section .card {
  width: 22rem;
  border: none;
}

.serviceplans-section h2 {
  font-size: 50px;
  font-weight: bold;
  color: #4314ba;
}
.serviceplans-section .card {
  padding: 0 15px;
  border: 1px solid rgba(0,0,0,.125);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}
.serviceplans-section .card .card-body .price-content-sectionA {
  text-align: center;
  padding: 0 10px;
}
.serviceplans-section .card .card-body .price-content-sectionA h3 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0;
}
.serviceplans-section .card .card-body .price-content-sectionA span {
  font-weight: 600;
  font-size: 18px;
}
.price-content-sectionA h4 {
  font-weight: 600;
  font-size: 22px;
  margin-top: 20px;
  text-transform: lowercase;
}
.serviceplans-section .card .card-body .card-title {
  font-weight: bold;
  margin: 31px 0 11px;
  font-size: 21px;
  padding: 0 10px;
  text-transform: uppercase;
}
.serviceplans-section .card .card-body {
  padding: 10px 0 16px;
  color: #6d5ec5;
}
.serviceplans-section .card .card-body .create-account-btn a {
  font-weight: 600;
  background-image: linear-gradient(
    to right top,
    #3913b8,
    #4828ba,
    #5539bc,
    #6248bd,
    #6d56be
  );
  color: white;
  margin: 0px 10px 0px;
  padding: 11px 20px 14px 0;
  min-width: auto;
  text-align: left;
  border-radius: 20px;
}
.create-account-sec {
  padding-top: 5px;
  font-weight: 600;
}
.serviceplans-section .card .card-body .create-account-btn a span img {
  width: 16%;
  background: #b295ff;
  padding: 9px;
  border-radius: 10px;
  margin-left: -7px;
  transform: rotate(0deg);
  margin-right: 10px;
}
.includes-content h5 {
  font-weight: 700;
  margin-top: 20px;
  font-size: 22px;
}
.includes-content p {
  font-weight: 600;
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 5px;
}
.includes-content {
  padding: 0 10px;
}
.includes-content p span img {
  width: 6%;
  margin-right: 10px;
  transform: rotate(17deg);
}
.tbs {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}
.modal-left-img, .modal-right{
  width: 50% !important;
}

/* footer */

.landing-footer-section {
  margin-top: 50px;
  background: #4e5f7d !important;
  color: white !important;
  padding: 100px 0;
}

.landing-footer-section ul li {
  list-style: none;
  padding: 10px 0;
  width: 45%;
  margin: 0 auto;
}
.inner-landing-footer .img1,
.inner-landing-footer .img2{
  width: 60%;
  cursor: pointer;
}
.inner-landing-footer .img1{
  margin-right: 10px;
  margin-bottom: 10px;
}
.landing-footer-section ul li a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}
.popup-module {
  padding-top: 35px;
}
.popup-module a {
  color: white;
  text-decoration: none;
  font-size: 26px;
  font-weight: bold;
  margin-right: 5px;
}
tbs{
  display: flex;
}
.modal-left-img{
  height: 585px;
  width: 50%;
}
.modal-left-img .landingPopupSideImage{
  height: 92%;
  width: 100%;
}
/*.modal-left-img img{*/
/*  margin-bottom: 30px;*/
/*  margin-left: 0px !important;*/
/*}*/


@media only screen and (max-width: 1245px) {
  .landing-inner-section-image .border-section-05 {
    left: -47%;
    top: 59px;
    transform: rotate(134deg);
}
.landing-inner-section-image {
  width: auto;
  margin: 0 auto;
  padding-top: 50px;
}
  .tbs {
    display: flex;
  }

  .ReactModal__Content {
    padding: 40px 20px !important;
  }

}
@media only screen and (max-width: 1187px) {
  .serviceplans-section .container {
    max-width: 94%;
  }
  .landing-inner-section-image .border-section-05 {
    left: -52%;
    top: 59px;
    transform: rotate(
134deg
);
}
}

@media only screen and (max-width: 1068px) {
  .serviceplans-section .card .card-body .create-account-btn a {
    margin: 0px 13px 0px;
  }
  .ReactModal__Content {
    width: 70% !important;
    height: 50% !important;
    top: 3% !important;
  }
  .landing-inner-section-image {
    width: auto;
  }
  .serviceplans-section .container {
    max-width: 100%;
  }
  .serviceplans-section .card {
    width: 20rem;
  }
  .landing-inner-section-image img{
    width: 164px;
    height: 172px;
  }
  .signupandlogingBtn {
    position: absolute;
    right: -17px;
  }
  .serviceplans-section .card .card-body .create-account-btn a {
    min-width: auto !important;
  }
  .landing-inner-section-image .border-section-07 {
    top: -26px;
    left: 31%;
    transform: rotate(196deg);
  }
  .landing-inner-section-image .border-section-03 {
    top: 54px;
    left: 34%;
    transform: rotate(84deg);
  }
  .landing-center-div {
    background: #3e4d64;
    padding: 27px 7px;
    width: auto;
    margin: 0 auto;
    z-index: 1;
    border-radius: 58px;
  }
  .landing-inner-section-image .border-section-05 {
    left: -46%;
    top: 59px;
    transform: rotate(134deg);
  }
}

@media only screen and (max-width: 768px) {
  .common_border {
    height: 135px !important;
    width: 135px !important;
  }
  .landing-inner-section-image img{
    width: 126px;
    height: 121px;
  }
  .modal-inner-section-data{width: 95% !important; padding: 10px !important;}
  .ReactModal__Content {
    width: 97% !important;
    height: 55% !important;
    top: -2% !important;
  }

  .inner-div-bg {
    background: #113a58;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    border: 6px solid #4e5f7d;
  }
  .landing-inner-section-image img {
    position: absolute;
    width: 136px;
    height: 136px;
    top: 0;
    left: 0;
    transform: rotate(-45deg);
  }
  .landing-center-div {
    width: auto;
    padding: 37px 19px;
    border-radius: 31px;
  }
  .landing-inner-section-image span {
    width: 118px;
    height: 0;
    position: absolute;
    bottom: 3px;
    left: 73%;
    top: 149px;
  }
  .border-section-04,
  .border-section-05 {
    display: none;
  }
  .landing-inner-section-image .border-section-02 {
    left: 4%;
    top: 39px;
  }
  .border-section-06 {
    left: 6%;
    top: -61px;
  }
  .landing-inner-section-image .border-section-03 {
    top: 30px;
    left: 44%;
  }
  .landing-inner-section-image .border-section-07 {
    top: -22px;
    left: 41%;
  }

  .landing-section .landing-inner-section h1 {
    font-size: 25px;
  }
  .landing-inner-section-image .border-section-06 {
    left: 3%;
    top: -31px;
  }
  .serviceplans-section .card {
    width: 49%;
    margin-bottom: 25px;
  }
  .landing-footer-section ul li {
    width: 54%;
  }
  .card-flex-section {
    flex-wrap: wrap;
  }
  .tbs {
    display: flex;
    flex-wrap: wrap;
  }
  .modal-left-img, .modal-right{
    width: 100% !important;
  }
  .modal-left-img {
   margin-left: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .common_border {
    display: none;
  }
  .signupandlogingBtn {
    position: absolute;
    right: -17px;
    top: 20px;
}
.landing-inner-section{padding-top: 30px;}
  .serviceplans-section .card {
    width: 100%;
  }
  .landing-inner-section-image {
    padding-top: 0;
  }
  ul {
    padding-left: 0;
  }
  .landing-section .share-content-section {
    margin-top: 19px;
    padding: 0 10px;
  }
  .landing-section {
    padding-bottom: 33px;
  }
  .landing-footer-section ul li {
    width: 100%;
  }

  .landing-center-div h4 {
    font-size: 16px;
    padding-bottom: 4px;
  }
  .popup-module {
    padding-top: 22px;
  }
  .popup-module a {
    font-size: 16px;
  }
  .landing-section .landing-inner-section h2 {
    font-size: 24px;
  }
  .landing-section .landing-inner-section h3 {
    padding-top: 17px;
    font-size: 19px;
  }
  .landing-section .landing-inner-section h1 {
    font-size: 21px;
  }
  .landing-section .share-content-section p {
    font-size: 14px;
  }
  .serviceplans-section h2 {
    font-size: 36px;
    margin-bottom: 25px !important;
  }
  .serviceplans-section .card .card-body .create-account-btn a span img {
    width: 11%;
  }

  .modal-inner-section-data{width: 95% !important; padding: 10px !important;}
  .modal-inner-section-data {
    width: 100% !important;
    padding: 10px !important;
    height: auto;
}
.ReactModal__Content {
  width: 97% !important;
  height: 72% !important;
  top: -20% !important;
}
  .inner-landing-footer .img1,
  .inner-landing-footer .img2{
    width: 45%;
  }
  .inner-landing-footer .img1{
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 375px) {
  .serviceplans-section .card .card-body .price-content-sectionA h3 {
    font-size: 34px;
  }
  .serviceplans-section .card .card-body .create-account-btn a span img {
    width: 16%;
  }
  .price-content-sectionA h4 {
    font-weight: 600;
    font-size: 22px;
    margin-top: 11px;
    text-transform: lowercase;
  }
  .landing-footer-section {
    margin-top: 22px;
    padding: 85px 0;
  }
  .popup-module a {
    font-size: 14px;
  }
  .landing-section .landing-inner-section h2 {
    font-size: 21px;
  }
  .landing-section .landing-inner-section h1 {
    font-size: 20px;
  }
  .landing-inner-section {
    padding-top: 20px;
  }
  .signupandlogingBtn {
    position: absolute;
    right: -17px;
    top: 10px;
  }
  .inner-landing-footer .w-50{
    width: 100% !important;
  }
   .inner-landing-footer .img1,
   .inner-landing-footer .img2{
     width: 90%;
   }
  .inner-landing-footer .img1{
    margin-right: 10px;
    margin-bottom: 10px;
  }

}
.LoginSignUpModal-Container .ReactModal__Content {
  height: 70% !important;
}

/*app*/
@media only screen and (max-width: 1140px) {
  .appContainer .img1 {
    height: 40px;
  }
  .appContainer .img2 {
    height: 40px;
    width: 130px;
  }
}
