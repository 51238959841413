.header-section {
    background: #7030a0;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: relative;
}

.devider {
    border-top: 2px solid #5a4860;
    margin-top: 3px;
}

.header-section .right-side-text {
    position: absolute;
    right: 2%;
    top: 20px;
}

.right-side-text h6 {
    font-weight: 600;
    font-size: 14px;
}

.right-side-text h6 span {
    text-transform: capitalize;
    font-size: 14px;
    margin: 0;
}

.header-section h1 {
    margin: 0;
    font-weight: 600;
    font-size: 30px;
}
.notification_icon .MuiSvgIcon-root {
    width: 25px !important;
    height: 25px !important;
}
.notification_icon .MuiButton-contained {
    box-shadow: none;
    background-color: transparent;
    color: white;
    padding: 0;
    min-width: auto;
}
.notification_icon .MuiButton-contained:hover {
    box-shadow: none;
    background-color: transparent;
}
.notification_content{
    max-width: 400px;
    padding: 10px;
}
.notification_content .notification_body{
    padding: 10px 0;
}
.notification_content .notification_body .notification_time{
    color: #9f9f9f;
    margin: 0;
}
.notification_content .notification_body .notification_details{
    width: 100%;
}
.notification_content .notification_body:not(:last-child){
    border-bottom: 1px solid #e5e5e5;
}
.notification_content .content_img{
    max-width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;
}
.notification_content .MuiTypography-body1{
    padding: 0;
    margin-bottom: 5px;
}
#simple-popover .MuiPopover-paper{
    max-height: 350px;
    overflow-y: scroll;
}

/* mediaQuery */
@media only screen and (max-width: 768px) {
    .header-section .right-side-text {
        position: absolute;
        right: 1%;
        top: 87px;
    }
}

@media only screen and (max-width: 540px) {
    .header-section h1 {
        font-size: 25px;
    }

    .header-section .right-side-text {
        position: absolute;
        right: 1%;
        top: 75px;
    }

    .header-section .right-side-text h6 {
        font-size: 14px;
    }
    .right-side-text {
        width: 100%;
    }
    .notification_icon{
        position: absolute;
        top: -30px;
        right: 10px;
        margin-right: 0 !important;
    }
}