
.my-profile-tab .react-tabs .react-tabs__tab {
    width: 19.99% !important;
}

input[type="file"] {
    background: transparent;
    border: none;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: 'Rubik', sans-serif;
    font-size: inherit;
    font-weight: 500;
    outline: none;
    padding: 1rem 50px 1rem 10px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    border: 1px dotted;
}

.my-profile-tab .react-bootstrap-table th:last-child {
    width: 17% !important;
}

.my-profile-tab .react-bootstrap-table td, .my-profile-tab .react-bootstrap-table th {
    text-align: left !important;
}

.my-profile-tab .table-2 .react-bootstrap-table th:last-child {
    width: 40% !important;
}

.myprofile-left-section {
    border: 2px solid #eee;
    padding: 20px;
    border-radius: 10px;
}
.row {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;

}
.profile-left-btn a, .profile-right-btn a {
    border: 1px solid #eee;
}

.profile-left-btn {
    text-align: left;
}

.profile-right-btn {
    text-align: right;
}

.myprofile-left-section .form-group {
    margin-bottom: 10px;
}

.myprofile-left-section .col-sm-2 {
    padding-left: 0;
    padding-right: 0;
}

.myprofile-left-section .form-label {
    font-weight: 600;
    margin-bottom: 0;
}

.myprofile-left-section h3 {
    font-size: 19px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #7030a0;
}

.myprofile-left-section .custom-btn {
    width: 41%;
    margin: 0 auto;
}

.myprofile-left-section .custom-btn:hover {
    background: #7030a0;
    border-color: #7030a0;
}

.bar1, .bar2, .bar3, .bar4, .bar5 {
    height: 2px;
    background-color: #7030a0;
    margin: 2px 0;
    transition: 0.4s;
    margin-right: 10px;
    width: 30px;
    cursor: pointer;
}

.sidebar_item{
    height: calc(100vh - 160px);
    overflow: auto;
}
.modal-content-list ul {
    padding-left: 21px;
}

.modal-content-list ul li {
    list-style: none;
}

.modal-content-list ul li a {
    color: black;
    font-size: 16px;
    font-weight: 600;
    /*text-decoration: none;*/
}

.modal-content-list ul li a:hover {
    color: #7030a0;
    font-weight: 600;
}

.modal-title-content h4 {
    font-weight: 600;
    color: #7030a0;
    margin-left: 21px;
    margin-top: 20px;
}

.modal-title-content {
    border-bottom: 2px solid #7030a0;
}

.close-icon {
    position: absolute;
    top: 7px;
    right: 10px;
    cursor: pointer;
}

.my-profile-tab .react-tabs .react-tabs__tab {
    padding: 19px 9px !important;
}

.profile-main-section .col-lg-1, .profile-main-section .col-md-2 {
    padding-left: 0;
}

.profile-main-section option, .profile-main-section select {
    font-size: 16px !important;
}

/* menu Modal */
.menudrawer {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 2;
    width: 300px;
    transition: 0.3s ease;
    transform: translate(-100%, 0);
}

.menudrawer.active {
    transform: translate(0%, 0);
}

.overlay {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: 0.3s ease;
    pointer-events: none;
}

.overlay.active {
    opacity: 1;
    pointer-events: all;
}

.table-contact-data .table th:nth-child(5n+4) {
    width: 9%;
    text-align: center !important;
    padding-left: 0 !important;
}

.profile-main-section .table-contact-data .react-bootstrap-table .table th:nth-child(6n+5) {
    width: 10%;
    text-align: center !important;
    padding-left: 0 !important
}

.profile-main-section .table-contact-data .react-bootstrap-table .table td:nth-child(6n+5), .profile-main-section .table-contact-data .react-bootstrap-table .table td:nth-child(6n+4) {
    text-align: center !important;
    padding-left: 0 !important
}

.login-history-myprofile-section .react-bootstrap-table {
    height: 696px;
    overflow: auto;
}

.login-history-myprofile-section .react-bootstrap-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.login-history-myprofile-section .react-bootstrap-table::-webkit-scrollbar {
    background-color: #F5F5F5;
    height: 10px;
    width: 5px;
}

.login-history-myprofile-section .react-bootstrap-table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
}

.form-control {
    padding: 0.375rem .35rem;
}
.menu-bars{
    padding: 0;
}

@media only screen and (max-width: 1420px) {
    .myprofile-left-section .form-label {
        font-size: 15px;
    }

    .profile-main-section .col-sm-5 {
        flex: 0 0 50.666667%;
        max-width: 50.666667%;
    }

    .profile-main-section .col-sm-3 {
        flex: 0 0 32%;
        max-width: 32%;
    }
}

@media only screen and (max-width: 1280px) {
    .user-information-section .react-tabs .react-tabs__tab {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .myprofile-left-section {
        /*margin-bottom: 25px;*/
    }

    .profile-main-section .col-lg-5, .profile-main-section .col-md-12 {
        padding-left: 0;
    }

    .profile-main-section .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .menu-bars {
        margin-bottom: 20px;
    }

}

@media only screen and (max-width: 991px) {
    .profile-main-section .col-md-12 {
        padding-left: 0;
    }

    .menu-bars {
        margin-bottom: 20px;
    }

}

@media only screen and (max-width: 988px) {
    .modal-title-content h4 {
        font-size: 20px;
    }

    .modal-content-list ul li a {
        font-size: 15px;
    }
}

@media only screen and (max-width: 560px) {
    .profile-main-section .col-sm-5 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .profile-main-section .col-sm-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .profile-main-section .col-sm-5, .profile-main-section .col-sm-3 {
        padding-left: 0;
    }

    .user-information-section .react-tabs .react-tabs__tab {
        display: block;
        width: 100% !important;
    }
}


/*profile-image-css*/
.img {
    width: 185px;
    height: 160px;
    background-color: #7030a0;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}

.img img {
    width: 140px;
    height: 140px;
    padding: 3px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, .6);
    margin: auto;
}

.img Button {

}

.img img:after {
    content: "";
    position: absolute;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    left: calc(50% - 91.5px);
    top: 20px;
    border: 3px solid rgba(255, 255, 255, .4);
}

.img img:before {
    content: "";
    position: absolute;
    width: 190px;
    height: 190px;
    border-radius: 50%;
    left: calc(50% - 95px);
    top: 15.5px;
    border: 2px solid rgba(255, 255, 255, .2);
}

.main-div {
    position: absolute;
    left: 52%;
    top: 100px;
    border-radius: 50%;
    font-size: 15px;
}

.main-div input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
}

.imgicon {
    height: 10px;
    width: 10px;
    padding: 16px !important;
}

.myProfileModel {
    background-color: #7030a0;
    color: white
}
.myProfileModel span{
    color: white
}