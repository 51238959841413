
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.notranslate .public-DraftEditor-content {line-height: 1rem;}
input[type=checkbox]{
  cursor: pointer !important;
  /* width: 16px;
    height: 18px */
}


.custom-text-color {
  color: #7030a0 !important;
}
.clearfix {
  clear: both;
}
/* common css header */
p p,h4 p,span p,ul li p , a p ,h5 p,h2 p,h3 p,h1 p,h6 p{margin-bottom: 0 !important;}
.devider {
  margin-top: 2px;
  border: 1px solid #eee;
}
.card-title{font-weight: 600;}
th{font-weight: 600;}
 .table thead th.table-bordered th{padding: 10px 10px;}
.table td{border-color: darkgray !important; font-size: 17px !important; padding: 5px 0;}
.table thead th{border-color: darkgray !important; font-size: 19px !important}
 input , select{border-color: darkgray !important;}
 input{font-size: 1rem !important;}
 .custom-text-color{font-weight: 600;}


.blink_me {
    position: absolute;
    top: 0;
    right: -80%;
    color: red;
    font-size: 11px;
    font-weight: 500;
    user-select: none;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}