.btnfirst {
    position: relative;
    top: 2px;
    padding-right: 10px;
}


.afterBtn {
    position: relative;
    top: 2px;
}

.newReleaseMyInfo-container .max_days_label {
    font-weight: 500;
}
.newReleaseMyInfo-container span {
    border: none;
    position: relative;
}

.box .demo-wrapper{
    min-height: 385px;
}

.newReleaseMyInfo-container .selectOption{
    cursor: pointer ;
}

.section1 .sort-order .MuiOutlinedInput-input{
    padding: 3px;
    width: 45px;
    text-align: center;
}