.IntermediatePage{
    background: #382e50;
    color: white;
    text-align: center;
    font-family: cursive;
    height: 100vh;
}
.IntermediatePage .text_heading{
    font-size: 20px;
    margin-bottom: 40px;
}
.IntermediatePage .login_btn,
.IntermediatePage .logo_img,
.IntermediatePage .main_img{
    margin-bottom:25px;
}
.IntermediatePage .login_btn button{
    text-decoration: none;
    font-size: 18px;
    color: white;
    border: 5px solid white;
    background: transparent;
    padding: 5px 20px;
    width: 150px;
    border-radius: 0 !important;
}
.IntermediatePage .login_btn button:hover{
    background: transparent !important;
    border: 5px solid white !important;
}
.IntermediatePage .login_btn button:hover{}