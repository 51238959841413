.faq-inner-content h5,.faq-inner-content p{
    display: flex;
}

.faq-inner-content h5 span{
    padding-left: 5px;
}

.faq-inner-content p span{
    padding-left: 11px;
}