
.payment-section form {
    width: 30vw;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
    margin: 0 auto;
}

.plan-details-section {

    border-radius: 7px;
    padding: 11px;
    margin: 0 auto;
    width: 100%;
}
.plan-details-section .plandescription{
    width: 80%;
}

.payment-section label {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 0;
}

.StripeElement {
    padding-top: 10px;
    padding: 10px 5px ;
    border: 1px solid #a9a9a9;
    border-radius: 5px !important;
}

.cardNumber_field {
    position: relative;
}

.cardNumber_field .cardPlaceholder {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardNumber_field .cardPlaceholder span {
    margin: 0 2px;
}

.payment-section-data button {
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: #7030a0;
    font-weight: 600;
    margin: 10px 15px;
    background-color: #f8f9fa;
    border: 1px solid #eee;
}

.ErrorMessage {
    color: red;
    display: flex;
    align-items: center;
    padding: 11px 0 0;
    font-size: 15px;
    margin-top: 0px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 1;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
}

.ErrorMessage svg {
    margin-right: 5px;
}

/* Buttons and links */
.payment-section button {
    background: #7030a0;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    margin-left: 0;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

.payment-section button:hover {
    filter: contrast(115%);
}

.payment-section button:disabled {
    opacity: 0.5;
    cursor: default;
}

.payment-section .col, .payment-section .col-1, .payment-section .col-10, .payment-section .col-11, .payment-section .col-12, .payment-section .col-2, .payment-section .col-3, .payment-section .col-4, .payment-section .col-5, .payment-section .col-6, .payment-section .col-7, .payment-section .col-8, .payment-section .col-9, .payment-section .col-auto, .payment-section .col-lg, .payment-section .col-lg-1, .payment-section .col-lg-10, .payment-section .col-lg-11, .payment-section .col-lg-12, .payment-section .col-lg-2, .payment-section .col-lg-3, .payment-section .col-lg-4, .payment-section .col-lg-5, .payment-section .col-lg-6, .payment-section .col-lg-7, .payment-section .col-lg-8, .payment-section .col-lg-9, .payment-section .col-lg-auto, .payment-section .col-md, .payment-section .col-md-1, .payment-section .col-md-10, .payment-section .col-md-11, .payment-section .col-md-12, .payment-section .col-md-2, .payment-section .col-md-3, .payment-section .col-md-4, .payment-section .col-md-5, .payment-section .col-md-6, .payment-section .col-md-7, .payment-section .col-md-8, .payment-section .col-md-9, .payment-section .col-md-auto, .payment-section .col-sm, .payment-section .col-sm-1, .payment-section .col-sm-10, .payment-section .col-sm-11, .payment-section .col-sm-12, .payment-section .col-sm-2, .payment-section .col-sm-3, .payment-section .col-sm-4, .payment-section .col-sm-5, .payment-section .col-sm-6, .payment-section .col-sm-7, .payment-section .col-sm-8, .payment-section .col-sm-9, .payment-section .col-sm-auto, .payment-section .col-xl, .payment-section .col-xl-1, .payment-section .col-xl-10, .payment-section .col-xl-11, .payment-section .col-xl-12, .payment-section .col-xl-2, .payment-section .col-xl-3, .payment-section .col-xl-4, .payment-section .col-xl-5, .payment-section .col-xl-6, .payment-section .col-xl-7, .payment-section .col-xl-8, .payment-section .col-xl-9, .payment-section .col-xl-auto {
    padding-left: 0;
    padding-right: 0;
}

.country-code {
    text-align: right;
}

.country-code label {
    padding-right: 10px;
}

.payment-section .form-group.row {
    display: flex;
    align-items: center;
}

.payment-section .form-group {
    margin-bottom: 9px;
}

.MuiCircularProgress-colorPrimary {
    color: white !important;
}

/*.payment-section button:disabled {
    opacity: 1 !important;
    cursor: default;
}*/

.MuiCircularProgress-indeterminate {
    width: 25px !important;
    height: 25px !important;
}

@media only screen and (max-width: 1400px) {
    .payment-section form {
        width: 45vw;
    }
}

@media only screen and (max-width: 989px) {
    .plan-details-section {
        padding: 20px;
    }

    .paymnet-plan-data h6 {
        width: 40%;
    }
}

@media only screen and (max-width: 991px) {
    .payment-section form {
        width: 58vw;
    }
}

@media only screen and (max-width: 768px) {
    .paymnet-plan-data h6 {
        width: 50%;
    }

    .paymnet-popup {
        flex-wrap: wrap;
    }

    .plan-details-section {
        width: 100% !important;
        margin-bottom: 20px;
    }

    .payment-section .container {
        padding-left: 0;
        padding-right: 0;
    }

    .payment-section form, .paymnet-checkout-section {
        width: 100% !important;
    }
}

.formPlaintextService5 {
    width: 10px;
}

@media only screen and (max-width: 699px) {
    .payment-section form {
        width: 90vw;
        padding: 15px;
    }
}


.InputContainer .InputElement::-webkit-input-placeholder::before {
    color: red;
    content: "Line 1\A Line 2\A Line 3\A" !important;
}

.servicePlanDetail {
    max-height: 422px !important;
}

#selectStateDD {
    font-size: 14px;
}

.btn-button{
    height: 50px;
}

.selected-plan-info{
    margin-top: 30px;
    padding: 20px;
    width: 80%;
    box-shadow: 0px 0px 0px 0.5px rgb(50 50 93 / 10%), 0px 2px 5px 0px rgb(50 50 93 / 10%), 0px 1px 1.5px 0px rgb(0 0 0 / 7%);
}

@media (max-width: 1100px) {
    .selected-plan-info{
        width: 100%;
    }
}

.selected-plan-info .imag-box{
    background: #F9F9F9;
    width: 106px;
    height: 106px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.selected-plan-info .checkin-text{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #1F2A40;
    padding: 22px 0;
}

.selected-plan-info .billed-price{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 10px;
}

.selected-plan-info .plan_price{
    margin-bottom: 10px;
}

.Starter .billed-price{
    color: #008EFF;

}

.Basic .billed-price{
    color: #FF238C;

}

.Silver .billed-price{
    color: #C64DFF;

}

.Gold .billed-price{
    color: #FFB800;

}

.Platinum .billed-price{
    color: #00D823;

}

.Ultra .billed-price{
    color: #C90189;

}