.wizardadditem .with_Sugg {
    position: relative !important;
    width: auto;
    padding-top: 0;
    left: 0;
}
.wizardadditem{
    position: relative;
}
.wizardadditem .box .with_Sugg .import-suggestion-Btn {
    position: relative;
    top: 0;
    right: 0;
    background-color: #7030a0;
    height: 40px;
    border: none;
}
@media (max-width: 576px) {
    .deceased_model .MuiNativeSelect-select,
    .deceased_model .MuiInputBase-input{
        width: 130px !important;
    }
}
.shard_model .MuiTableCell-root{
    padding: 5px 10px !important;
}
.shard_model .MuiTableContainer-root{
    overflow-x: auto;
}
 .MuiNativeSelect-select.MuiNativeSelect-select{
    padding-right: 20px !important;
}
 .add_items .modal-dialog-centered{
     max-width: 950px;
 }
 .add_items .makeStyles-margin-47{
     margin: 0;
 }
 .add_items .MuiTableCell-root{
     padding: 10px;
 }

 .custom-txtMsgBox {
     padding: 10px !important;
     height: 49px !important;
     font-weight: bold !important;
     font-size: 18px !important;
 }
 .custom-txtItemBox {
     padding: 10px !important;
     height: 49px !important;
     font-weight: bold !important;
     font-size: 18px !important;
 }

 .custom-wishMsgBox {
     margin-bottom: 18px;
     border: 1px solid #ced4da;
     border-radius: 0.25rem;
 }

.add_wishes_modal .accordion_main label{
    margin-bottom: 0!important;
    font-size: 15px;
}
.add_wishes_modal .modal-body .accordion_main .form-control{
    height: calc(1.5em + 10px + 2px);
}

.add_wishes_modal .modal-body .accordion_main textarea{
    height: unset!important;
}