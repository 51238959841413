.main-wishes {
    display: flex;
}

.main-wishes img {
    height: 45px;
    width: 45px;
    margin-left: 10px;
    border-radius: 50%;
    margin-top: 8px;
    border: 2px solid #6d56be;
}

.WishesbyUser {
    overflow: hidden;
}

.WishesbyUser Button {
    margin: 10px;
    float: right;
    overflow: hidden;
}

.main-container-wishes {
    margin: 0 auto;
    margin-bottom: 4px;
}

.mainDivForInvite {
    max-height: 40px;
    align-items: center;
}

.mainDivForInvite .findBtn {
    width: 170px;
}
.genie_model {
    height: 45px;
    cursor: pointer;
}
.Shared-me_details{
    position: fixed;
    width: 100%;
    background: white;
    z-index: 9;
}
.Shared_details_content{
    padding-top: 75px;
}
