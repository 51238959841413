.public-DraftStyleDefault-block {
    margin: 0em 0 !important;
}

.ColorPicker-Popup {
    width: 250px;
}

.notes-content {
    border: 1px solid darkgray;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    overflow-y: auto;
    cursor: pointer;
    min-height: 355px;
}

.notes-content p {
    height: 9px;
}

.tox-tinymce {
    width: 100% !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.with_Sugg{
    position: absolute;
    right: 25px;
}

.box .import-suggestion-Btn {
    background-color: #7030a0;
    height: 40px;
    border: none;
}

@media (max-width: 1200px) {
    .with_Sugg {
        position: absolute;
        width: auto;
        left: -31%;
        padding-top: 15%;
    }
}
@media (max-width: 768px) {
    .with_genie {
         position: static !important;
         padding: 0 0 0 90px !important;
    }
    .with_Sugg {
        position: relative;
        left: 0;
        padding-top: 0px;

    }
}
@media (max-width: 576px) {
    .with_genie {
        padding: 0 0 0 30px !important;
    }
    .with_Sugg {
        width: 60%;
    }
}