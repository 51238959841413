.form-data-userHistory .form-group{margin-bottom: 7px;}
.form-data-userHistory .form-control {    height: calc(1.2em + .60rem + 2px);}
.form-data-userHistory  .textArea { height: calc(6.5em + .75rem + 2px);}
.form-data-userHistory input{font-size: 17px;}
.form-data-userHistory label {margin-bottom: 2px;font-weight: 600;}
.form-data-userHistory .table{margin-bottom: 0;}
.user-history .table td{font-size: 15px !important;}
.user-history  .custom-text-color{font-weight: 600;font-size: 20px;}
.form-data-userHistory .table-responsive thead td{font-size: 14px;}
.form-data-userHistory .table-responsive::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 20px;background-color: #F5F5F5;}
.form-data-userHistory .table-responsive::-webkit-scrollbar{width: 5px;background-color: #F5F5F5;}
.form-data-userHistory .table-responsive::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px #eee;background-color: #555;} 
/* my-profile */
.my-profile-form  .postalCode{width: 49%;} 