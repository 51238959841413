.sharedRelease .MuiTableCell-root {
    padding: 0px !important;
    padding-left: 5px !important;
}
.modelLegend .legend-content {
    margin-left: auto;
    margin-right: auto;
    width: 83%;
    box-shadow: 5px 5px 7px grey;
    margin-bottom: 9px;
    position: relative;
}
.sharedRelease .MuiTableContainer-root{
    overflow-x: hidden;
}

@media (max-width: 1200px) {
    .sharedRelease .MuiTableContainer-root{
        overflow-x: auto;
    }
}

@media (max-width: 576px) {
    .sharedRelease .MuiTable-stickyHeader{
        min-width: 360px;
    }
}