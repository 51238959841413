.how_to_video_container{
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    gap: 50px;
}
.how_to_video{
    border: 1px solid darkgrey;
    border-radius: 10px;
}